body {
    margin: 0;
    max-width: 100vw;
    overflow-x: hidden;
}

/* OVERRIDE KIT HEADER BEHAVIOUR AT SMALL VIEWPORTS */
@media (max-width: 900px) {
  header > .MuiToolbar-root {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
}


.map-result-slider .slick-track {
    display: flex
}
.map-result-slider .slick-slide {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    width: 250px !important;
    padding: 0 8px;
}

.map-result-slider .relative {
    position: relative;
}